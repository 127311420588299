import "./App.css";
import { useState, useEffect, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import axios from "axios";
import loader from "./images/loader.gif";

const App = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [inputText, setInputText] = useState("");
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const [theme, setTheme] = useState("dark");
  const chatBoxRef = useRef(null);
  const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();

  const toggleTheme = () => {
    setTheme((prevTheme) => prevTheme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    setInputText(transcript);
  }, [transcript]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  if (!browserSupportsSpeechRecognition) {
    return <div className="error">Browser doesn't support speech recognition.</div>;
  }

  const handleAudioUpload = (event) => {
    setUploadedAudio(event.target.files[0]);
  };

  const transcribeAudio = async () => {
    if (!uploadedAudio) {
      alert("ابتدا فایل را بارگزاری کنید");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("audio", uploadedAudio);

    try {
      const response = await axios.post("https://dailog.ir/transcribe", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setMessages((prev) => [...prev, { type: "ai", content: response.data.transcription }]);
      setUploadedAudio(null);
    } catch (error) {
      setMessages((prev) => [...prev, { type: "ai", content: "Error transcribing file. Please try again." }]);
    } finally {
      setIsLoading(false);
    }
  };

  const sendEchoRequest = async () => {
    if (!inputText.trim()) return;

    setMessages((prev) => [...prev, { type: "user", content: inputText }]);
    setIsLoading(true);
    resetTranscript();

    try {
      const response = await axios.post("https://dailog.ir/echo", { message: inputText });
      setMessages((prev) => [...prev, { type: "ai", content: response.data.response }]);
    } catch (error) {
      setMessages((prev) => [...prev, { type: "ai", content: "خطا در ارسال پیام. دوباره امتحان کنید." }]);
    } finally {
      setIsLoading(false);
      setInputText("");
    }
  };

  const summarizeText = async () => {
    if (!inputText.trim()) return;
    setIsLoading(true);
    resetTranscript();

    try {
      const response = await axios.post("https://dailog.ir/summarize", { inputText });
      setMessages((prev) => [...prev, { type: "user", content: inputText }, { type: "ai", content: response.data.summary }]);
      setInputText("");
    } catch (error) {
      setMessages((prev) => [...prev, { type: "ai", content: "Error summarizing. Please try again." }]);
    } finally {
      setIsLoading(false);
    }
  };

  const getKeywords = async () => {
    if (!inputText.trim()) return;
    setIsLoading(true);
    resetTranscript();

    try {
      const response = await axios.post("https://dailog.ir/keywords", { inputText });
      setMessages((prev) => [...prev, { type: "user", content: inputText }, { type: "ai", content: "Keywords: " + response.data.keywords }]);
      setInputText("");
    } catch (error) {
      setMessages((prev) => [...prev, { type: "ai", content: "Error extracting keywords. Please try again." }]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleListening = () => {
    isListening ? SpeechRecognition.stopListening() : SpeechRecognition.startListening({ continuous: true, language: "fa-IR" });
    setIsListening(!isListening);
  };

  return (
      <div className={`app-container ${theme}`}>
        <div className="chat-container">
          <header>
            <h1>DAIlog</h1>
            <div className="header-buttons">
              <button className="about-button">درباره ما</button>
              <button className="profile-button">پروفایل</button>
              <button onClick={toggleTheme} aria-label="Switch Theme">
                {theme === "dark" ? "حالت روشن" : "حالت تاریک"}
              </button>
            </div>
          </header>


          <div className="chat-box" ref={chatBoxRef}>
            {messages.map((msg, idx) => (
                <div key={idx} className={`message ${msg.type}`}>
                  <div className="message-content">{msg.content}</div>
                </div>
            ))}
            {isLoading && <img src={loader} alt="Loading..." className="loader-gif" />}
          </div>

          <div className="button-container">
            <button onClick={summarizeText} title="ارائه خلاصه ی متن">خلاصه سازی</button>
            <button onClick={() => alert('در دست توسعه')} title="بزودی">دستیار تلگرامی</button>
            <button onClick={() => alert('در دست توسعه')} title="بزودی">دستیار ایمیل</button>
            <button onClick={getKeywords} title="استخراج کلمات مهم">کلمات کلیدی</button>
          </div>

          <div className="input-area">
            <input
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && sendEchoRequest()}
                placeholder="Type a message..."
            />
            <button onClick={toggleListening} className={`icon-button ${isListening ? "recording" : ""}`} title={isListening ? "Stop" : "Start"} aria-label="Toggle Listening">
              {isListening ? "◉" : "◯"}
            </button>
            <button onClick={sendEchoRequest} disabled={isLoading || !inputText.trim()} className="icon-button send" title="Send" aria-label="Send">
              ▶
            </button>
          </div>

          <div className="audio-upload">
            <input type="file" accept="audio/*" onChange={handleAudioUpload} style={{ display: "none" }} id="audioFile" />
            <label htmlFor="audioFile" className="audio-button" aria-label="Upload Audio">
              {uploadedAudio ? "تبدیل به صوت" : "بارگذاری صدا"}
            </label>
            {uploadedAudio && (
                <button onClick={transcribeAudio} className="transcribe-button" aria-label="Transcribe Audio">Convert to Text</button>
            )}
          </div>
        </div>
      </div>
  );
};

export default App;
